<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">Advancly Loans</h2>
      </div>
    </div>

    <div class="border border-solid border-blue-200 rounded overflow-hidden">
      <datatable
        :url="url"
        :ajax="true"
        :ajaxPagination="true"
        :exportable="true"
        :index="true"
        :reverse-index="true"
        :data="repayments"
        :columns="columns"
        :loading="loading"
        :query="searchQuery"
        :pageDetails="true"
        :rangeable="dateRange"
        ref="table"
      >      
        <template #header>
          <div class="pt-4">            
            <search-form
              placeholder="Search users names, emails, phone numbers, bvn, etc."
              class="mb-5"
              v-model="searchQuery"
              @submit="reloadTable"
            />
            <div class="flex justify-between">
              <div class="flex items-start flex-wrap xl:flex-no-wrap">
                <div class="flex flex-wrap items-center w-full">
                  <span class="processor-filter-title">Filters:</span>
                  <template v-for="(filter, index) in filters">
                    <span
                      v-html="filter.title"
                      :key="index"
                      class="processor-filter-item"
                      :class="{ active: currentFilter === filter }"
                      @click.prevent="toggleFilter(filter)"
                    />
                  </template>
                </div>
              </div>
              <div class="flex flex-wrap items-center">
                <!-- <span class="processor-filter-title">Exports:</span> -->
                <button
                  class="btn btn-purple-outline btn-sm mb-2 mr-2"
                  @click.prevent="$refs.table.exportAll()"
                >
                  <ion-icon name="cloud-download-outline" class="text-base" />
                  <span class="ml-2">Export All Results</span>
                </button>
                <button
                  class="btn btn-blue-outline btn-sm mb-2"
                  @click.prevent="$refs.table.exportData()"
                >
                  <ion-icon name="cloud-download-outline" class="text-base" />
                  <span class="ml-2">Export Filtered Results</span>
                </button>
              </div>
            </div>
          </div>
        </template>
      </datatable>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      url: `${this.$baseurl}/admin/personal/repayments/advancly-loans`,
      searchQuery: "",
      currentFilter: null,
      loading: false,
      repayments: [],
      columns: [
        {
          name: "user",
          th: "Staff",
          render: (repayment, user) => {
            if (!user) {
              return "N/A";
            }
            return `${user.name} ${user.last_name}`;
          }
        },
        {
          name: "user",
          th: "Phone No",
          render: (repayment, user) => {
            if (!user) {
              return "N/A";
            }
            return `${user.phone_no}`;
          }
        },
        {
          name: "loan",
          th: "Amount Disbursed",
          render: repayment => {
            if (!repayment) {
              return "N/A";
            }
            return `<span className="text-blue-300">₦ ${this.$options.filters.currency(
              repayment?.loan?.loan_amount || 0
            )}</span>`;
          }
        },
        {
          name: "due_date",
          th: "Due Date",
          render: (repayment, due_date) =>
            moment(due_date).format("MMM Do YYYY")
        },
        {
          name: "loan",
          th: "Loan Tenure",
          render: (repayment, loan) => {
            if (!loan) {
              return "N/A";
            }
            return loan?.tenure;
          }
        },
        {
          name: "loan",
          th: "Interest Rate",
          render: (repayment, loan) => {
            if (!loan?.interest_rate) {
              return "N/A";
            }
            return `${loan?.interest_rate}%`;
          }
        },
        {
          name: "amount",
          th: "Amount",
          render: (repayment, amount) =>
            `<span className="text-blue-300">₦ ${this.$options.filters.currency(
              amount
            )}</span>`
        },
        {
          name: "default_charge",
          th: "Default Charge",
          render: repayment =>
            `<span className="text-blue-300">₦ ${this.$options.filters.currency(
              repayment.default_charge?.amount || 0
            )}</span>`
        },
        {
          name: "status",
          th: "Status",
          render: repayment => {
            const today = moment().format("YYYY-MM-DD");
            switch (repayment.status) {
              case "paid":
                return `<div class="badge badge-green-soft-outline">
                        Paid
                    </div>`;
              case "transferred":
                return `<div class="badge badge-green-soft-outline">
                        Transferred
                    </div>`;
              case "not_due":
                if (today > repayment.due_date) {
                  return `<div class="badge badge-orange-soft-outline">
                        Due
                    </div>`;
                }
                return `<div class="badge badge-blue-soft-outline">
                        Not Due
                    </div>`;
              case "due":
                if (repayment.default_charge) {
                  return `<div class="badge badge-red-soft-outline">
                        Defaulting
                    </div>`;
                }
                return `<div class="badge badge-orange-soft-outline">
                        Due
                    </div>`;
              case "processing":
                return `<div class="badge badge-blue-soft-outline">
  						Processing
                    </div>`;
              case "failed":
                return `<div class="badge badge-red-soft-outline">
                        Failed
                    </div>`;
              case "pending":
                return `<div class="badge badge-orange-soft-outline">
                    Pending
                </div>`;
              default:
                if (repayment.defaulted) {
                  return `<div class="badge badge-red-soft-outline">
                    Default
                </div>`;
                }
                break;
            }
          }
        },
        {
          name: "payment_method",
          th: "Payment Method",
          render: repayment => {
            if (!repayment?.payment_method) {
              return "N/A";
            }
            return repayment?.payment_method;
          }
        },
        {
          name: "settlement_status",
          th: "Settlement Status",
          render: repayment => {
            switch (repayment?.settlement_status) {
              case "settled":
                return `<div class="badge badge-green-soft-outline">
                    Settled
                </div>`;
              case "unsettled":
                return `<div class="badge badge-red-soft-outline">
                    Unsettled
                </div>`;
              default:
                return `<div class="badge badge-blue-soft-outline">
                    Pending
                </div>`;
            }
          }
        },
        {
          name: "id",
          th: "Repayment ID",
          render: repayment => repayment?.id
        },
        {
          name: "loan",
          th: "Disburse Date",
          render: (repayment, loan) => {
            if (!loan?.disbursed_at) {
              return "N/A";
            }
            return moment(loan.disbursed_at).format("MMM Do YYYY");
          }
        },
        {
          className: "w-1/10",
          name: "comment",
          th: "Comments",
          render: repayment => {
            return `
                  <div class="flex items-center">
                    <button
                      class="btn btn-blue btn-sm mr-5"
                      data-click="click(${repayment.id})"
                    >
                      View
                    </button>
                  </div>
                `;
          }
        }
      ],
      filters: [
        { title: "upcoming", name: "upcoming" },
        { title: "due", name: "due" },
        { title: "defaulting", name: "defaulting" }
      ],
      dateRange: {
        value: {
          startDate: null,
          endDate: null,
        },
        format: "dd/mm/yyyy",
      },
    };
  },
  methods: {
    toggleFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null;
      } else {
        this.currentFilter = filter;
      }

      this.$refs.table.clickedFilter(this.currentFilter);
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    click(defaultUser, type = "user") {
      if (type === "user") {
        this.openCommentModal(defaultUser);
        return;
      }
      this.$router.push({
        name: "staff-view",
        params: { staffId: defaultUser?.user_id }
      });
    },
  }
};
</script>
<style lang="sass">
.processor-select-input
  @apply bg-white border border-blue-200 duration-300 font-medium px-6 py-3 relative rounded-sm text-gray-600
  font-size: 13px
  &:hover
    @apply bg-blue-200 border-blue-200 text-blue-900
  &:focus-within
    @apply border-blue-500 text-blue-500
.processor-filter-title
  @apply font-bold mb-2 mr-2 text-sm uppercase w-full
.processor-filter-item
  @apply bg-white border cursor-pointer font-bold mb-2 mr-2 px-2 py-2 rounded-sm text-xs text-gray-600 tracking-wide uppercase
  &:hover
    @apply bg-blue-100 border-blue-500 text-blue-500
  &.active
    @apply bg-blue-500 border-blue-500 text-white
</style>
